import ReactGA from 'react-ga';
import { googleAnalyticsTrackingId } from '../config/config';

ReactGA.initialize(googleAnalyticsTrackingId);

export function trackScreen(screen = '') {
  const normalizedScreenName = screen.endsWith('/') ? screen : `${screen}/`;
  if (normalizedScreenName.includes('mot-de-passe')) return;
  ReactGA.pageview(normalizedScreenName);
}

export function trackEvent(category, action, label, value) {
  const event = { category: String(category), action: String(action) };
  if (typeof label === 'string') { event.label = label; }
  if (typeof value === 'number') { event.value = value; }
  ReactGA.event(event);
}

export function trackUser({ _id }) {
  ReactGA.set({ userId: _id });
}

// Do not update Intercom here : Intercom has a threshold of 20 udpates / session
// this method can be call several times, e.g. on each change of email input
export function trackUserEmail() {}

export function setProperty(key, value) {
  ReactGA.set({ [key]: value });
}
